.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: 5rem;
  margin-bottom: 1.5rem;
  font-size: 15px;
  color: #3d3d3d;
}

.seachbar {
  margin-bottom: 4.2rem;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 각 이미지 스타일 */
.image-card {
  width: 350px;
  margin-right: 30px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px #cfcfcf;
  border-radius: 20px;
}

.image-card img {
  max-width: 100%; /* 이미지의 최대 너비를 부모 요소에 맞게 설정하여 크기를 조절 */
  height: auto; /* 가로 세로 비율 유지 */
  border-radius: 15px;
}

.desc {
  color: #3d3d3d;
}
